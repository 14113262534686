




























import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import IconSvg from '@/components/IconSvg.vue'
import ContextInputRange from '@/components/ContextInputRange.vue'

// * Current download link in iOS PWA opens a web view with an image icon
// * It does not seem possible to peak an image to save it in iOS PWA

@Component({
  components: {
    IconSvg,
    ContextInputRange,
  },
  computed: mapState(['ui', 'strings', 'settings', 'images']),
})
export default class ContextSave extends Vue {
  ui!: any
  settings!: any
  strings!: any
  images!: any

  get downloadHref() {
    const uri = this.images.output.replace(/^data:image\/\w+;base64,/, '')
    return `data:application/octet-stream;base64,${uri}`
  }

  get pixelScale() {
    return this.settings.pixelScale
  }

  set pixelScale(value: number) {
    this.$store.commit('updateSetting', { key: 'pixelScale', value })
  }

  onClickBackToMask() {
    this.$emit('masksedit')
  }

  updateOutput() {
    this.$emit('settingschange')
  }
}
