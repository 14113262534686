






import { Component, Vue } from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'

@Component({
  components: { InlineSvg },
})
export default class Illustration extends Vue {
  get src() {
    return `./img/svg/illustration.svg`
  }

  tick = 0
  tickInterval = 0
  pixels: string[] = Array(15).fill('black')
  isHovering = false

  mounted() {
    const rate = 1000 / 12
    this.tickInterval = setInterval(() => {
      this.tick++
    }, rate)
  }

  beforeDestroy() {
    clearInterval(this.tickInterval)
  }

  getRandomColor() {
    const max = 10
    const lightness = ~~(Math.random() * max) + 0
    return `hsl(0, 0%, ${lightness * 8}%)`
  }

  get pixelVars() {
    const tick = this.tick % 6
    const pixels: any = {}

    const a = tick
    const b = tick + 6
    const c = tick + 12
    this.pixels[a] = this.getRandomColor()
    this.pixels[b] = this.getRandomColor()
    this.pixels[c] = this.getRandomColor()

    for (let i = 0; i < this.pixels.length; i++) {
      pixels[`--color-pixel-${i + 1}`] = this.pixels[i]
    }

    return pixels
  }

  get styles() {
    return this.pixelVars
  }
}
