









import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Disc from '@/components/Disc.vue'

@Component({
  components: { Disc },
  computed: mapState(['ui']),
})
export default class NewComponent extends Vue {
  ui!: any
}
