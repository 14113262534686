































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import IconSvg from '@/components/IconSvg.vue'

@Component({
  components: { IconSvg },
  computed: mapState(['ui']),
})
export default class ImageMask extends Vue {
  @Prop() data!: Mask

  ui!: any

  get classes() {
    return {
      _drag: this.isDragging,
      _resize: this.isResizing,
    }
  }

  get styles() {
    return {
      width: `${this.data.w}px`,
      height: `${this.data.h}px`,
      left: `${this.data.x}px`,
      top: `${this.data.y}px`,
    }
  }

  // Lifecycle

  beforeDestroy() {
    if (this.ui.maskHighlight === this.data.id) {
      this.$store.commit('updateUI', { key: 'maskHighlight', value: null })
    }
  }

  // Interaction

  interactionTimeout = 0

  onInteractionStart() {
    clearTimeout(this.interactionTimeout)
    this.$store.commit('updateUI', {
      key: 'maskHighlight',
      value: this.data.id,
    })
  }

  onInteractionEnd() {
    this.isDragging = false
    this.isResizing = false
    this.$emit('change')

    this.interactionTimeout = setTimeout(() => {
      if (this.ui.maskHighlight === this.data.id) {
        this.$store.commit('updateUI', { key: 'maskHighlight', value: null })
      }
    }, 1200)
  }

  // Drag & Resize

  dragStartX = 0
  dragStartY = 0
  maskStartX = 0
  maskStartY = 0
  maskStartW = 0
  maskStartH = 0

  isDragging = false
  isResizing = false

  onMouseup() {
    document.removeEventListener('mousemove', this.onMousemoveMask)
    document.removeEventListener('mousemove', this.onMousemoveHandle)
    document.removeEventListener('mouseup', this.onMouseup)
    this.onInteractionEnd()
  }

  onTouchend() {
    document.removeEventListener('touchmove', this.onTouchmoveMask)
    document.removeEventListener('touchmove', this.onTouchmoveHandle)
    document.removeEventListener('touchend', this.onTouchend)
    this.onInteractionEnd()
  }

  onDblclick() {
    this.$store.commit('removeMask', this.data.id)
  }

  // Drag

  emitFromDragCoordinates(x: number, y: number) {
    this.$emit('drag', {
      ...this.data,
      x: this.maskStartX + (x - this.dragStartX),
      y: this.maskStartY + (y - this.dragStartY),
    })
  }

  // Drag Mouse

  onMousemoveMask(e: MouseEvent) {
    this.emitFromDragCoordinates(e.clientX, e.clientY)
  }

  onMousedownMask(e: MouseEvent) {
    this.isDragging = true
    this.dragStartX = e.clientX
    this.dragStartY = e.clientY
    this.maskStartX = this.data.x
    this.maskStartY = this.data.y
    this.onInteractionStart()
    document.addEventListener('mousemove', this.onMousemoveMask)
    document.addEventListener('mouseup', this.onMouseup)
  }

  // Drag Touch

  onTouchmoveMask(e: TouchEvent) {
    this.emitFromDragCoordinates(e.touches[0].pageX, e.touches[0].pageY)
  }

  dblclickTouchAwait = false
  dblclickTouchTimeout = 0

  onTouchstartMask(e: TouchEvent) {
    if (this.dblclickTouchAwait) {
      this.onDblclick()
    } else {
      this.dblclickTouchAwait = true
      this.dblclickTouchTimeout = setTimeout(() => {
        this.dblclickTouchAwait = false
      }, 200)
    }

    this.isDragging = true
    this.dragStartX = e.touches[0].pageX
    this.dragStartY = e.touches[0].pageY
    this.maskStartX = this.data.x
    this.maskStartY = this.data.y
    this.onInteractionStart()
    document.addEventListener('touchmove', this.onTouchmoveMask)
    document.addEventListener('touchend', this.onTouchend)
  }

  // Resize

  emitFromResizeCoordinates(x: number, y: number) {
    this.$emit('resize', {
      ...this.data,
      w: this.maskStartW + (x - this.dragStartX),
      h: this.maskStartH + (y - this.dragStartY),
    })
  }

  // Resize Mouse

  onMousemoveHandle(e: MouseEvent) {
    this.emitFromResizeCoordinates(e.clientX, e.clientY)
  }

  onMousedownHandle(e: MouseEvent) {
    this.isResizing = true
    this.dragStartX = e.clientX
    this.dragStartY = e.clientY
    this.maskStartW = this.data.w
    this.maskStartH = this.data.h
    this.onInteractionStart()
    document.addEventListener('mousemove', this.onMousemoveHandle)
    document.addEventListener('mouseup', this.onMouseup)
  }

  // Resize Touch

  onTouchmoveHandle(e: TouchEvent) {
    this.emitFromResizeCoordinates(e.touches[0].pageX, e.touches[0].pageY)
  }

  onTouchstartHandle(e: TouchEvent) {
    this.isResizing = true
    this.dragStartX = e.touches[0].pageX
    this.dragStartY = e.touches[0].pageY
    this.maskStartW = this.data.w
    this.maskStartH = this.data.h
    this.onInteractionStart()
    document.addEventListener('touchmove', this.onTouchmoveHandle)
    document.addEventListener('touchend', this.onTouchend)
  }

  // Delete

  onClickDelete() {
    this.$store.commit('removeMask', this.data.id)
  }
}
