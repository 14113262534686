










import { Component, Prop, Vue } from 'vue-property-decorator'
import IconSvg from '@/components/IconSvg.vue'

@Component({
  components: { IconSvg },
})
export default class Disc extends Vue {
  @Prop() data!: any

  get imageSrc() {
    if (!this.data.image) {
      return null
    }
    return `./${this.data.image}`
  }
}
