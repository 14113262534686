







































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Logo from '@/components/Logo.vue'
import IconSvg from '@/components/IconSvg.vue'
import Drawer from '@/components/Drawer.vue'
import Dropzone from '@/components/Dropzone.vue'
import DiscButton from '@/components/DiscButton.vue'

@Component({
  components: { IconSvg, Logo, DiscButton, Drawer, Dropzone },
  computed: mapState(['ui', 'images']),
})
export default class App extends Vue {
  @Ref() readonly drawer!: Drawer

  images!: any
  ui!: any

  queryDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
  isStandalone = window.matchMedia('(display-mode: standalone)').matches
  isIos = /iPhone|iPad|iPod/.test(navigator.userAgent)

  get classes() {
    return {
      _dragging: this.ui.isDragging,
      _intro: !this.images.input,
    }
  }

  // Lifecycle

  created() {
    if (this.isStandalone) {
      document.documentElement.classList.add('is-standalone')
      this.$store.commit('updateUI', { key: 'isStandalone', value: true })
    }
    if (this.isIos) {
      document.documentElement.classList.add('is-ios')
      this.$store.commit('updateUI', { key: 'isIos', value: true })
    }
    document.addEventListener('workerupdated', this.onWorkerUpdated)
  }

  mounted() {
    this.onDarkModeChange(this.queryDarkMode)
    this.queryDarkMode.addListener(this.onDarkModeChange)

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.ui.isReloading) return
        this.$store.commit('updateUI', { key: 'isReloading', value: true })
        location.reload()
      })
    }
  }

  // Worker

  onWorkerUpdated(e: any) {
    this.$store.commit('updateUI', { key: 'worker', value: e.detail })
    this.$store.commit('updateUI', { key: 'updateAvailable', value: true })
  }

  // Changes

  onDarkModeChange(query: any) {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (metaThemeColor) {
      if (query.matches) {
        metaThemeColor.setAttribute('content', '#000')
      } else {
        metaThemeColor.setAttribute('content', '#fff')
      }
    }
  }

  // Drawer

  get drawerExpanded() {
    return this.ui.drawerExpanded
  }

  set drawerExpanded(value) {
    this.$store.commit('updateUI', { key: 'drawerExpanded', value })
  }

  onExpandDrawer() {
    this.drawer.focusFirstinContent()
  }

  // Buttons

  onClickUpdate() {
    if (!this.ui.worker || !this.ui.worker) return
    this.ui.worker.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  onClickClearMasks() {
    this.$store.commit('removeAllMasks')
    this.$store.commit('updateUI', { key: 'showingPreview', value: false })
    this.drawerExpanded = false
  }

  onClickUpload() {
    const inputUpload = document.querySelector(
      '#input-editor'
    ) as HTMLInputElement
    inputUpload?.click()
  }

  onClickRestart() {
    this.$store.commit('removeAllMasks')
    this.$store.commit('updateImage', { key: 'input', value: null })
    this.drawerExpanded = false
  }
}
