




















import { Component, Vue } from 'vue-property-decorator'
import Illustration from '@/components/Illustration.vue'

@Component({
  components: { Illustration },
})
export default class Intro extends Vue {}
