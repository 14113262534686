





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class ContextInputRange extends Vue {
  @Prop() value?: number

  get listeners() {
    return {
      ...this.$listeners,
      input: (e: any) => this.$emit('input', e.target.value),
    }
  }

  get classes() {
    return {
      _focus: this.isFocussed,
      _hover: this.isHovered,
    }
  }

  // Progress

  get progress() {
    const val = this.value || 0
    const { min = '0', max = '1' } = this.$attrs
    const range = +max - +min
    return (val - +min) / range
  }

  get progressPercent() {
    return `${this.progress * 100}%`
  }

  // Events

  isFocussed = false
  isHovered = false

  onFocus() {
    this.isFocussed = true
  }

  onBlur() {
    this.isFocussed = false
  }

  onMouseenter() {
    this.isHovered = true
  }

  onMouseleave() {
    this.isHovered = false
  }
}
