























































import { Component, Ref, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import throttle from 'lodash.throttle'
import Loader from '@/components/Loader.vue'
import EditorUploader from '@/components/EditorUploader.vue'
import EditorStage from '@/components/EditorStage.vue'
import ImageMask from '@/components/ImageMask.vue'
import Intro from '@/components/Intro.vue'
import ContextMasks from '@/components/ContextMasks.vue'
import ContextSave from '@/components/ContextSave.vue'

@Component({
  components: {
    Loader,
    EditorUploader,
    EditorStage,
    ImageMask,
    Intro,
    ContextMasks,
    ContextSave,
  },
  computed: mapState(['ui', 'settings', 'strings', 'images']),
})
export default class Home extends Vue {
  @Ref('baseCanvas') readonly canvasElement!: HTMLCanvasElement

  // Vuex

  ui!: any
  settings!: any
  strings!: any
  images!: any

  get masks(): Mask[] {
    return this.$store.state.masks
  }

  set masks(value) {
    this.$store.commit('updateMasks', value)
  }

  // Step

  get step(): 'start' | 'mask' | 'save' {
    switch (true) {
      case !this.images.input:
        return 'start'
      case !this.showingPreview:
        return 'mask'
      case this.showingPreview:
        return 'save'
      default:
        return 'start'
    }
  }

  // Lifecycle

  mounted() {
    this.$store.commit('updateImage', {
      key: 'context',
      value: this.canvasElement.getContext('2d'),
    })
  }

  // Preview

  get showingPreview() {
    return this.ui.showingPreview
  }

  set showingPreview(value: boolean) {
    this.$store.commit('updateUI', { key: 'showingPreview', value })
  }

  // Context

  onClickGoToSave() {
    this.updateOutput()
    this.showingPreview = true
  }

  onClickBackToMask() {
    this.showingPreview = false
  }

  // Upload

  isLargeImage = false

  onLoadStageImage(image: HTMLImageElement) {
    this.isLargeImage = image.width > 999 || image.height > 999
    this.$store.commit('removeAllMasks')
    this.addMaskAtCentre()
  }

  // Masks

  addMask(x = 36, y = 36, w = 144, h = 36) {
    this.$store.commit('addMask', { x, y, w, h })
  }

  get highlightedMask() {
    if (!this.ui.maskHighlight) {
      return null
    }
    return this.masks.find((x) => x.id === this.ui.maskHighlight)
  }

  getStageCentre() {
    let x = 36
    let y = 36
    let right = 144
    let bottom = 144
    const stage = document.querySelector('.stage') as HTMLElement
    const context = document
      .querySelector('.stage-context')
      ?.getBoundingClientRect()
    if (stage && context) {
      const stageCentreX = stage.clientWidth / 2
      const stageCentreY = stage.clientHeight / 2
      x = Math.floor(stageCentreX - context.x)
      y = Math.floor(stageCentreY - context.y)
      right = Math.min(stage.clientWidth - context.x, context.width)
      bottom = Math.min(stage.clientHeight - context.y, context.height)
    }
    return { x, y, right, bottom }
  }

  addMaskAtCentre() {
    const { x, y, right, bottom } = this.getStageCentre()
    const target = { x: x - 72, y: y - 18 }
    this.masks.forEach((mask) => {
      if (mask.x !== target.x || mask.y !== target.y) {
        return true
      }
      if (mask.x === target.x && target.x < right - 180) {
        target.x = target.x + 6
      }
      if (mask.y === target.y && target.y < bottom - 72) {
        target.y = target.y + 6
      }
    })
    this.addMask(target.x, target.y)
  }

  onClickAddMask() {
    this.addMaskAtCentre()
  }

  // Output

  updateOutput = throttle(() => {
    this.$store.dispatch('updateOutput')
  }, 100)
}
