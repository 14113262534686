































import { Component, Ref, Vue } from 'vue-property-decorator'
import { FocusTrap } from 'focus-trap-vue'

@Component({ components: { FocusTrap } })
export default class Drawer extends Vue {
  @Ref('card') readonly cardElement!: HTMLElement
  @Ref('header') readonly headerElement!: HTMLElement
  @Ref('content') readonly contentElement!: HTMLElement

  focusFirstinContent() {
    const focusable = [
      ...this.contentElement.querySelectorAll(
        '[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      ),
    ].filter((el) => !el.hasAttribute('disabled'))
    const first = focusable[0] as HTMLElement
    if (first) {
      first.focus()
    }
  }

  // Events

  onWheel(e: WheelEvent) {
    if (e.deltaX < -42) {
      this.$emit('dismiss')
    }
  }

  initialTouchX = 0

  onTouchstart(e: TouchEvent) {
    const firstTouch = e.touches[0]
    this.initialTouchX = firstTouch.pageX
  }

  onTouchmove(e: TouchEvent) {
    const firstTouch = e.touches[0]
    const delta = firstTouch.pageX - this.initialTouchX
    if (delta > 90) {
      this.$emit('dismiss')
    }
  }
}
