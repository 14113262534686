

































import { Component, Prop, Vue } from 'vue-property-decorator'
import Disc from '@/components/Disc.vue'

@Component({
  components: { Disc },
})
export default class DiscButton extends Vue {
  @Prop() href?: string
  @Prop() route?: any
  @Prop() disc!: any
}
