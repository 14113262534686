






import { Component, Prop, Vue } from 'vue-property-decorator'
import InlineSvg from 'vue-inline-svg'

@Component({
  components: { InlineSvg },
})
export default class IconSvg extends Vue {
  @Prop() readonly name!: string

  get src() {
    return `./img/svg/icon-${this.name}.svg`
  }
}
