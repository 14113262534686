























import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import IconSvg from '@/components/IconSvg.vue'
import ContextMessage from '@/components/ContextMessage.vue'

@Component({
  components: {
    IconSvg,
    ContextMessage,
  },
  computed: mapState(['ui', 'masks', 'strings', 'images']),
})
export default class ContextMasks extends Vue {
  ui!: any
  strings!: any
  images!: any
  masks!: any

  onClickAddMask() {
    this.$emit('masksadd')
  }

  onClickGoToSave() {
    this.$emit('masksdone')
  }
}
